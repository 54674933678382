import { useEffect, useState } from 'react';
import './App.css';

import Login from './components/Login';
import Search from './components/Search';

function App() {
  const [token, setToken] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');

    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const signOut = () => {
    setToken();
    localStorage.setItem('token', '');
  };

  return (
    <div className="App">
      {!token ?
        <Login
          updateToken={(createdToken) => setToken(createdToken)}
        /> :
        <Search
          token={token}
          signOut={signOut}
        />
      }
    </div>
  );
}

export default App;
