import { forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';

import { apiURL } from '../config';
import trackstarLogo from '../images/trackstar-blue.png';
import schoolBusImage from '../images/school-bus-illustration.png';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Login({
  updateToken,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!password) {
      setModalTitle('Notice!');
      setModalText('Password cannot be blank.');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${apiURL}/v1/login`, {
        password,
      });

      setLoading(false);

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        updateToken(response.data.token);
      } else {
        setModalTitle('Error:');
        setModalText('There was an error signing you in, please try again.');
      }
    } catch (e) {
      let message = 'There was an error signing you in, please try again.';

      if (e && e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalTitle('Error:');
      setModalText(message);
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return;
    }

    return (
      <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, .5)'}}>
        <CircularProgress style={{color: '#fff', top: '50%', left: '50%', position: 'absolute'}}/>
      </div>
    );
  };

  return (
    <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>
      {renderLoading()}
      <Box sx={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 10, marginBottom: 10}}>
        <Card>
          <CardContent sx={{textAlign: 'center'}}>
            <img src={trackstarLogo} height="40px" />
            <Box sx={{marginTop: 3, marginBottom: 3}}>
              <img src={schoolBusImage} width="80%" />
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{width: '100%', marginTop: 5}}
              />

              <Button
                variant="outlined"
                type="submit"
                sx={{width: '100%', marginTop: 2}}
              >
                Login
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>

      <Dialog fullWidth maxWidth="sm" open={!!modalText} onClose={() => setModalText('')} TransitionComponent={Transition}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{modalText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setModalText('')}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Login;
